import { TopMovieSearchParams } from '@/models';
import { GIComment, GIResource } from '@/models/geoItem';
import GeoItemSearchComment from '@/components/Top/geoItemSearchComponents/GeoItemSearchComment.vue';
import GeoItemSearchLandApEmergency from '@/components/Top/geoItemSearchComponents/GeoItemSearchLandApEmergency.vue';
import GeoItemSearchJunkaiTenkenReportTenkenData from '@/components/Top/geoItemSearchComponents/GeoItemSearchJunkaiTenkenReportTenkenData.vue';
import GeoItemSearchLandAiDetections from '@/components/Top/geoItemSearchComponents/GeoItemSearchLandAiDetections.vue';
import { CarIndexParams } from '@/models/apis/cars/carRequest';
import { CommentIndexParams } from '@/models/apis/comment/commentRequest';
import { JunkaiTenkenIndexParams } from '@/models/apis/extJunkaiTenkenReportTenkenData/extJunkaiTenkenReportTenkenDataRequest';
import { DetectionsIndexParams } from '@/models/apis/extLandAiDetections/extLandAiDetectionsRequest';
import { EmergencyIndexParams } from '@/models/apis/extLandApEmergency/extLandApEmergencyRequest';

export default abstract class GIAbstractManager {
  constructor() {
    if (this.constructor === GIAbstractManager) {
      throw new TypeError('Abstract class "GIAbstractManager" ' +
        'cannot be instantiated directly.');
    }
  }

  abstract getResources(componentRef: InstanceType<
    typeof GeoItemSearchComment |
    typeof GeoItemSearchLandApEmergency |
    typeof GeoItemSearchJunkaiTenkenReportTenkenData |
    typeof GeoItemSearchLandAiDetections>): Promise<GIResource[]>;

  abstract getResourcesByParams(params:
    CarIndexParams |
    CommentIndexParams |
    JunkaiTenkenIndexParams |
    EmergencyIndexParams |
    DetectionsIndexParams |
    TopMovieSearchParams): Promise<GIResource[]>;

  abstract getResourceById(id: number) : Promise<GIResource | null>;
  // 現在のresourcesから新しいresourcesに対して、
  // 画面上の状態関連のプロパティを引き継ぐ
  abstract mergeStateInfo(newResources: GIResource[], currentResources: GIResource[]): GIResource[];

  // 現状付箋のみ作成、更新、削除ができるが、今後拡張する可能性があるので親クラスで定義
  abstract createResource(params: GIComment): Promise<GIComment>;

  abstract updateResource(params: GIComment): Promise<GIComment>;

  abstract deleteResource(params: GIComment): Promise<GIComment>;

  filterTabPaneElements(data: GIResource[]): GIResource[] {
    return data;
  }
}
