




































































import {
  defineComponent,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { JunkaiTenkenIndexParams } from 'src/models/apis/extJunkaiTenkenReportTenkenData/extJunkaiTenkenReportTenkenDataRequest';
import GeoItemSearchCommon from './GeoItemSearchCommon.vue';
import extJunkaiTenkenReportTenkenDataApi from '@/apis/extJunkaiTenkenReportTenkenData';
import {
  TenkenKubun,
  Hantei,
} from '@/models/apis/extJunkaiTenkenReportTenkenData/extJunkaiTenkenReportTenkenDataResponse';

interface SearchParams {
  tenkenKubuns: TenkenKubun[];
  hanteis: Hantei[];
}

interface GeoItemSearchJunkaiTenkenReportTenkenDataState {
  search: SearchParams;
  checkboxKeyPrefix: string;
  showTenkenKubun: boolean;
  showGroupedTenkenKubuns: Record<string, boolean>;
  groupedTenkenKubuns: Record<string, TenkenKubun[]>;
  showHantei: boolean;
  hanteis: Hantei[];
}

export default defineComponent({
  name: 'geo-item-search-junkai-tenken-report-tenken-data',
  setup(_, { emit }) {
    const initSearchState = (): SearchParams => {
      return {
        tenkenKubuns: [],
        hanteis: [],
      };
    };
    const state = reactive<GeoItemSearchJunkaiTenkenReportTenkenDataState>({
      search: initSearchState(),
      checkboxKeyPrefix: 'tenken',
      showTenkenKubun: false,
      showGroupedTenkenKubuns: {},
      groupedTenkenKubuns: {},
      showHantei: false,
      hanteis: [],
    });

    const getGroupedTenkenKubuns = (tenkenKubuns: TenkenKubun[]): Record<string, TenkenKubun[]> => {
      // 点検区分一覧データを「路上・土木」、「路上・施設」、「路下・土木」、「路下・施設」、「高架下」...ごとにグループ化する
      const results = Object.values(tenkenKubuns)
        .reduce((acc: Record<string, TenkenKubun[]>, tenkenKubun) => {
          const baseName = tenkenKubun.tenkengyoumu_base_name.replace(/巡回点検$/, '');
          const detailName = tenkenKubun.tenkengyoumu_detail_name.replace(/電気関係$|関係$/, '');
          const key = (baseName === detailName) ? baseName : `${baseName}・${detailName}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(tenkenKubun);
          return acc;
        }, {});

      return results;
    };

    const tenkenMaster = extJunkaiTenkenReportTenkenDataApi.getTenkenMaster();
    tenkenMaster.then((res) => {
      const masterData = res.data;
      state.groupedTenkenKubuns = getGroupedTenkenKubuns(masterData.tenken_kubuns);
      state.showGroupedTenkenKubuns = Object.keys(state.groupedTenkenKubuns)
        .reduce((acc: Record<string, boolean>, key) => {
          acc[key] = false;
          return acc;
        }, {});
      state.hanteis = masterData.hanteis;
    });

    const refSearchCommon = ref<InstanceType<typeof GeoItemSearchCommon>>();
    const getSearchParams = (): JunkaiTenkenIndexParams | null => {
      if (!refSearchCommon.value) return null;

      const { ts_from: dtFrom, ts_to: dtTo, ...other } = refSearchCommon.value.getSearchParams();
      return {
        dt_from: dtFrom,
        dt_to: dtTo,
        road_names: other.road_name_disps,
        directions: other.directions,
        tenken_kubuns: state.search.tenkenKubuns.map(e => e.id),
        hanteis: state.search.hanteis.map(e => e.id),
      };
    };
    const onChange = () => {
      emit('change');
    };

    return {
      ...toRefs(state),
      // refs
      refSearchCommon,
      // methods
      getSearchParams,
      onChange,
    };
  },
  components: { GeoItemSearchCommon },
});
