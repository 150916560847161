import GIAbstractManager from '@/lib/geo_item/GIAbstractManager';
import commentApi from '@/apis/comment';
import analyticsApi from '@/apis/analytics';
import { ensureDate } from '@/lib/dateHelper';
import { lineBreakToBR } from '@/lib/utils';
import { Lovs } from '@/models';
import { GIComment } from '@/models/geoItem';
import { CommentIndexParams } from 'src/models/apis/comment/commentRequest';
import { Comment } from 'src/models/apis/comment/commentResponse';
import GeoItemSearchComment from 'src/components/Top/geoItemSearchComponents/GeoItemSearchComment.vue';
import { CommentType } from 'src/models/apis/master/masterResponse';

interface InitParams {
  lovs: Lovs;
  commentTypeMap: Record<string, CommentType>;
}

export default class GICommentManager extends GIAbstractManager {
  private lovs: Lovs;
  private commentTypeMap: Record<string, CommentType>;

  constructor({ lovs, commentTypeMap }: InitParams) {
    super();
    this.lovs = lovs;
    this.commentTypeMap = commentTypeMap;
  }

  async getResources(
    componentRef: InstanceType<typeof GeoItemSearchComment>,
  ): Promise<GIComment[]> {
    const params = componentRef.getSearchParams();
    if (!params) {
      return [];
    }
    return this.getResourcesByParams(params);
  }

  async getResourcesByParams(params: CommentIndexParams): Promise<GIComment[]> {
    const { data } = await commentApi.index(params);
    return this._convData(data);
  }

  async getResourceById(id: number): Promise<GIComment> {
    const { data } = await commentApi.get(id);
    const [ret] = this._convData([data]);
    return ret;
  }

  private _convData(data: Comment[]): GIComment[] {
    const shareScopeMap = this.lovs.share_scope.map;
    const results: GIComment[] = [];
    data.forEach(e => {
      // topの新着の付箋で表示するための色番号
      const iconPath = this.commentTypeMap[e.comment_type].icon_path;
      const m = iconPath.match(/comment_icon_(\d+)\.png$/);
      results.push({
        ...e,
        ts: ensureDate(e.ts),
        lat: parseFloat(e.lat),
        lon: parseFloat(e.lon),
        commentTypeDisp: this.commentTypeMap[e.comment_type].comment_type_disp,
        iconPath,
        shareScopeDisp: shareScopeMap[e.share_scope].val,
        contentDisp: lineBreakToBR(e.content),
        isParentComment: !e.parent_id,
        isChildComment: !!e.parent_id,
        commentTypeColorCode: m ? m[1] : '',
        isSelected: false,
        updateTs: false,
        saveFiles: [],
        removeFileIds: [],
      });
    });
    return results;
  }

  mergeStateInfo(newResources: GIComment[], currentResources: GIComment[]): GIComment[] {
    const currentResourceMap = currentResources.reduce<Record<string, GIComment>>((acc, e) => {
      acc[e.id] = e; return acc;
    }, {});
    newResources.forEach(e => {
      if (currentResourceMap[e.id]) {
        e.isSelected = currentResourceMap[e.id].isSelected;
      }
    });
    return newResources;
  }

  private async logCommentAdd_(): Promise<void> {
    try {
      await analyticsApi.logCommentAdd();
    } catch (e) {
      console.warn('logCommentAdd failed');
      console.warn(e);
    }
  }

  private async logCommentDelete_(): Promise<void> {
    try {
      await analyticsApi.logCommentDelete();
    } catch (e) {
      console.warn('logCommentDelete failed');
      console.warn(e);
    }
  }

  async createResource(comment: GIComment): Promise<GIComment> {
    const { data } = await commentApi.create({
      lat: comment.lat,
      lon: comment.lon,
      share_scope: comment.share_scope,
      comment_type: comment.comment_type,
      content: comment.content,
      kp_uid: comment.kp_uid,
      angle: comment.angle,
      files: comment.saveFiles,
      parent_id: comment.parent_id,
    });
    this.logCommentAdd_();
    const [ret] = this._convData([data]);
    return ret;
  }

  async updateResource(comment: GIComment): Promise<GIComment> {
    const { data } = await commentApi.update(comment.id, {
      lat: comment.lat,
      lon: comment.lon,
      share_scope: comment.share_scope,
      comment_type: comment.comment_type,
      content: comment.content,
      kp_uid: comment.kp_uid,
      angle: comment.angle,
      update_ts: comment.updateTs,
      save_files: comment.saveFiles,
      remove_file_ids: comment.removeFileIds,
    });
    const [ret] = this._convData([data]);
    return ret;
  }

  async deleteResource(params: GIComment): Promise<GIComment> {
    const { data } = await commentApi.destroy(params.id);
    this.logCommentDelete_();
    const [ret] = this._convData([data]);
    return ret;
  }
}
