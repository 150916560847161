


import {
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api';
import { CandidateFile } from '@/models';
import {
  isImageMimeType,
  fileTypeIconFromFilePath,
} from '@/lib/fileHelper';
import { MAX_SAVABLE_FILES_COUNT } from '@/lib/commentHelper';

export default defineComponent({
  props: {
    previewFiles: {
      type: Array as PropType<CandidateFile[]>,
      required: true,
    },
    isChangeable: {
      type: Boolean,
      default: true,
    },
    acceptedFileTypes: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const refUploadFile = ref<HTMLInputElement>();
    const handleFileUpload = () => {
      const fileList = refUploadFile.value?.files;
      if (!fileList) return;
      emit('file-upload', fileList);
    };
    const removeFile = (fileId: number) => {
      emit('remove-file', fileId);
    };
    watch(() => props.previewFiles, () => {
      if (refUploadFile.value && props.previewFiles.length === 0) {
        refUploadFile.value.value = '';
      }
    });
    return {
      MAX_SAVABLE_FILES_COUNT,
      // refs
      refUploadFile,
      handleFileUpload,
      removeFile,
      isImageMimeType,
      fileTypeIconFromFilePath,
    };
  },
});
