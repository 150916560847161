import { CandidateFile } from '@/models';
import { getResizedImage } from '@/lib/imageHelper';
import { extension as convertMimeTypeToExtension, lookup as lookupMimeTypes } from 'mime-types';

export interface FileObj extends CandidateFile {
  file: File;
}

export async function convertFilesToFileObjs(fileList: FileList, maxId: number, limitMB: number): Promise<FileObj[]> {
  if (fileList.length === 0) { return []; }
  const fileObjs: FileObj[] = [];

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    if (file.type.includes('image/')) {
      await addImageFileObj(fileObjs, file, maxId + i + 1);
    } else {
      checkFileSize(file, limitMB);
      addGeneralFileObj(fileObjs, file, maxId + i + 1);
    }
  }
  return fileObjs;
}

export async function addImageFileObj(fileObjs: FileObj[], file: File, id: number): Promise<void> {
  // リサイズ後は確実に10MBを切るのでサイズ確認はしなくてよい.
  // 動画プレイヤーの付箋追加で切り出される1920x1080の画像を許容するため、2000x2000にリサイズする.
  const resizedImage = await getResizedImage(file, { maxWidth: 2000, maxHeight: 2000 });
  if (!resizedImage) return;
  fileObjs.push({
    id,
    content: URL.createObjectURL(resizedImage),
    type: file.type,
    name: file.name,
    file: new File([resizedImage], file.name, { type: file.type }),
    selected: false,
  });
}

export function addGeneralFileObj(fileObjs: FileObj[], file: File, id: number): void {
  fileObjs.push({
    id,
    content: URL.createObjectURL(file),
    type: file.type,
    name: file.name,
    file,
    selected: true,
  });
}

export function checkFileSize(file: File, limitMB: number): void {
  const fileSize = file.size;
  const limitSize = limitMB * 1024 * 1024;
  if (fileSize > limitSize) {
    throw new Error(`添付ファイルの最大サイズは${limitMB}MBです。`);
  }
}

export function fileTypeIconFromFilePath(filePath: string): string {
  const mimeType = lookupMimeTypes(filePath);
  if (!mimeType) return 'fa-file';
  const extension = convertMimeTypeToExtension(mimeType);
  if (!extension) return 'fa-file';
  if (extension === 'pdf') return 'fa-file-pdf';
  if (extension === 'doc' || extension === 'docx') return 'fa-file-word';
  if (extension === 'xls' || extension === 'xlsx') return 'fa-file-excel';
  if (extension === 'ppt' || extension === 'pptx') return 'fa-file-powerpoint';
  return 'fa-file';
}

export function isImageMimeType(fileType: string): boolean {
  return fileType.startsWith('image/');
}
