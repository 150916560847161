


import commentApi from '@/apis/comment';
import userApi from '@/apis/user';
import { Kilopost } from 'src/models/apis/movie/movieResponse';
import {
  getNearKps,
  getLocationDispOfKp,
  validateAngle,
  validateComment,
  fetchFileAsObjectInfo,
  ATTACHMENT_FILE_MAX_MB_DEFAULT,
  MAX_SAVABLE_FILES_COUNT,
  ACCEPTED_FILE_TYPES,
} from '@/lib/commentHelper';
import {
  defineComponent,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
  toRefs,
} from '@vue/composition-api';
import { AdminCommentIndexRequest } from 'src/models/apis/comment/adminCommentRequest';
import { AdminCommentIndexResponse } from 'src/models/apis/comment/adminCommentResponse';
import { Comment } from 'src/models/apis/comment/commentResponse';
import { CandidateFile } from '@/models';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { AxiosError } from 'axios';
import useMaster from '@/composables/useMaster';
import { lineBreakToBR, splitByLineBreak } from '@/lib/utils';
import { AdminUser } from '@/models/apis/user/adminUserResponse';
import { GIKp } from '@/models/geoItem';
import {
  convertFilesToFileObjs,
  FileObj,
  fileTypeIconFromFilePath,
  isImageMimeType,
} from '@/lib/fileHelper';
import PreviewFilesArea from '@/components/lib/PreviewFilesArea/index.vue';

type SearchParams = AdminCommentIndexRequest;

type CommentDisp = Comment & {
  contentDisp: string;
};

interface AdminCommentsEdit {
  id: number;
  comment_type: string;
  content: string | null;
  user_id: number | null;
  share_scope: number | null;
  angle: number | null;
  kpObj?: Kilopost;
  update_ts: boolean;
  contentTextareaRows?: number;
  kp_uid: string | null;
  lat?: string;
  lon?: string;
  saveFiles: FileObj[];
  removeFileIds: number[];
}

interface AdminCommentsTableList {
  ts_datetime: string;
  commentTypeDisp: string;
  shareScopeLabel: string;
  user_display_name: string;
  contentDisp: string;
  kpInfo?: string;
  angleDisp?: string;
  hasFile: string;
}

interface AdminCommentsState {
  showEditModal: boolean;
  editMode: 'edit' | 'create';
  showDestroyModal: boolean;
  showErrorModal: boolean;
  candidateFiles: CandidateFile[];
  search: SearchParams;
  editing: AdminCommentsEdit;
  filteredComments: CommentDisp[];
  users: AdminUser[];
  errorMsgs: string[];
}

export default defineComponent({
  name: 'admin-comments',
  setup() {
    const now = new Date();
    const initSearchState = (): SearchParams => {
      return {
        comment_type: null,
        user_id: null,
        dt_from: new Date(now.getFullYear(), now.getMonth() - 6, now.getDate()),
        dt_to: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        content: null,
        share_scope: null,
      };
    };
    const initEditingState = (): AdminCommentsEdit => {
      return {
        id: 0,
        comment_type: '',
        content: null,
        user_id: null,
        share_scope: null,
        angle: null,
        kpObj: {} as Kilopost,
        update_ts: false,
        kp_uid: null,
        saveFiles: [],
        removeFileIds: [],
      };
    };
    const state = reactive<AdminCommentsState>({
      showEditModal: false,
      editMode: 'edit',
      showDestroyModal: false,
      showErrorModal: false,
      candidateFiles: [],
      search: initSearchState(),
      editing: initEditingState(),
      filteredComments: [],
      users: [],
      errorMsgs: [],
    });
    const { state: msts } = useMaster();

    const resetSearchResults = () => {
      state.filteredComments = [];
    };
    const convComments = (data: AdminCommentIndexResponse): CommentDisp[] => {
      const results = data.map<CommentDisp>(e => {
        return {
          ...e,
          content: splitByLineBreak(e.content).join('\n'),
          contentDisp: lineBreakToBR(e.content),
        };
      });
      return results;
    };
    const doSearch = () => {
      resetSearchResults();
      const params = state.search;
      const reqParams = Object.assign({}, params);
      if (params.dt_to) {
        reqParams.dt_to = new Date(params.dt_to.valueOf() + 86400 * 1000);
      }
      commentApi.adminIndex(reqParams)
        .then(({ data }) => {
          if (!data || data.length === 0) {
            return;
          }
          state.filteredComments = convComments(data);
        });
    };

    onMounted(async() => {
      const [masters, { data }] = await Promise.all([
        window.master.$promise,
        userApi.adminIndex({}),
      ]);
      msts.commentTypes = masters.commentType.vals;
      msts.shareScopes = masters.lovs.share_scope.vals;
      msts.kpMap = masters.kpMap;
      msts.roadNameDispMap = masters.roadNameDispMap;
      state.users = data;
      doSearch();
    });

    const releaseAllFiles = () => {
      state.editing.saveFiles.forEach(elm => URL.revokeObjectURL(elm.content));
      state.editing.saveFiles = [];
      state.editing.removeFileIds = [];
      state.candidateFiles.forEach(elm => URL.revokeObjectURL(elm.content));
      state.candidateFiles = [];
    };

    onBeforeUnmount(() => {
      releaseAllFiles();
    });

    const getCommentTypeDisp = (type: string): string => {
      for (const commentType of msts.commentTypes) {
        if (commentType.comment_type === type) {
          return commentType.comment_type_disp;
        }
      }
      return type;
    };
    const getShareScopeLabel = (scope: number): string => {
      for (const shareScope of msts.shareScopes) {
        if (shareScope.key === scope) {
          return shareScope.val;
        }
      }
      return scope.toString();
    };
    const getKpInfo = (kpUid: string | null): string | undefined => {
      if (!kpUid) {
        return;
      }
      const kps = Array.from(msts.kpMap.values())
        .map(e => Array.from(e.values())).flat(2);
      const kpObj = kps.find(e => e.kp_uid === kpUid);
      if (!kpObj) {
        return;
      }
      return getLocationDispOfKp(kpObj, msts.roadNameDispMap);
    };

    const displayData = computed<AdminCommentsTableList[]>(() => {
      return state.filteredComments.map(comment => {
        return {
          ...comment,
          commentTypeDisp: getCommentTypeDisp(comment.comment_type),
          shareScopeLabel: getShareScopeLabel(comment.share_scope),
          kpInfo: getKpInfo(comment.kp_uid),
          angleDisp: comment.angle === null || comment.angle === undefined ? '' : `${comment.angle}°`,
          hasFile: comment.comment_files.length > 0 ? '有' : '',
        };
      });
    });
    const canSave = computed<boolean>(() => {
      const hasNoError = state.errorMsgs.length === 0;
      return validateComment(state.editing) && hasNoError;
    });
    const nearKpThresholdMeters = 100;
    const nearKps = computed<GIKp[]>(() => {
      if (!state.editing.lat || !state.editing.lon) {
        return [];
      }
      return getNearKps(
        {lat: state.editing.lat, lon: state.editing.lon},
        msts.kpMap,
        msts.roadNameDispMap,
        nearKpThresholdMeters,
      );
    });
    const editItemHasValidAngle = computed<boolean>(() => {
      return validateAngle(state.editing.angle);
    });
    const sortedPreviewFiles = computed<CandidateFile[]>(() => {
      return state.candidateFiles.sort((a, b) => a.id - b.id);
    });

    const save = async() => {
      try {
        if (state.editMode === 'edit') {
          await commentApi.adminUpdate(state.editing.id, {
            lat: state.editing.lat ?? '',
            lon: state.editing.lon ?? '',
            movie_id: null,
            share_scope: state.editing.share_scope || 0,
            comment_type: state.editing.comment_type,
            content: state.editing.content || '',
            kp_uid: state.editing.kp_uid || null,
            angle: state.editing.angle,
            update_ts: state.editing.update_ts,
            save_files: state.editing.saveFiles.map(e => e.file),
            remove_file_ids: state.editing.removeFileIds,
          });
          state.errorMsgs = [];
          state.showEditModal = false;
          releaseAllFiles();
          doSearch();
        }
      } catch (err) {
        state.errorMsgs = getErrorMessages(err as AxiosError);
      }
    };

    const edit = async(obj: Comment) => {
      const minRows = 4;
      let rows = splitByLineBreak(obj.content).length;
      rows = Math.max(rows, minRows);

      state.editing = {
        id: obj.id,
        user_id: obj.user_id,
        comment_type: obj.comment_type,
        content: obj.content,
        contentTextareaRows: rows,
        share_scope: obj.share_scope,
        angle: obj.angle,
        kp_uid: obj.kp_uid,
        lat: obj.lat,
        lon: obj.lon,
        update_ts: false,
        saveFiles: [],
        removeFileIds: [],
      };
      releaseAllFiles();
      state.errorMsgs = [];
      state.candidateFiles = [];

      obj.comment_files.forEach(async(file) => {
        const savedFileInfo = await fetchFileAsObjectInfo(file.file_path);
        if (!savedFileInfo || !savedFileInfo.url || !savedFileInfo.type) return;
        state.candidateFiles.push({
          id: file.id,
          content: savedFileInfo.url,
          type: savedFileInfo.type,
          name: file.file_name ?? '',
          selected: true,
        });
      });

      state.showEditModal = true;
      state.editMode = 'edit';
    };

    const tryDestroy = (obj: Comment) => {
      state.editing.id = obj.id;
      state.showDestroyModal = true;
    };
    const doDestroy = async() => {
      try {
        await commentApi.adminDestroy(state.editing.id);
        state.showDestroyModal = false;
        doSearch();
      } catch (err) {
        state.showDestroyModal = false;
        state.showErrorModal = true;
      }
    };

    const cancelEdit = () => {
      releaseAllFiles();
      state.showEditModal = false;
    };
    const handleFileUpload = async(fileList: FileList) => {
      state.errorMsgs = [];
      const uploadFilesCount = fileList.length + state.candidateFiles.length;
      try {
        if (uploadFilesCount > MAX_SAVABLE_FILES_COUNT) {
          throw Error('添付ファイルの最大数は' + MAX_SAVABLE_FILES_COUNT + '個です。');
        }
        // 新規ファイルのidを連番で割り振る(実際のidはサーバー側で振られる)
        const maxId = state.candidateFiles.map(e => e.id).reduce((a, b) => Math.max(a, b), 0);
        const fileObjs = await convertFilesToFileObjs(fileList, maxId, ATTACHMENT_FILE_MAX_MB_DEFAULT);
        state.candidateFiles.push(...fileObjs);
        state.editing.saveFiles.push(...fileObjs);
      } catch (error) {
        state.errorMsgs.push(...getErrorMessages(error as AxiosError));
      }
    };
    const removeFile = (fileId: number) => {
      state.candidateFiles = state.candidateFiles.filter(e => e.id !== fileId);
      const saveFile = state.editing.saveFiles.find(e => e.id === fileId);
      if (saveFile) {
        state.editing.saveFiles = state.editing.saveFiles.filter(e => e.id !== fileId);
        return;
      }
      state.editing.removeFileIds.push(fileId);
    };

    const fields = [
      { name: 'ts_datetime', label: '最終更新日時' },
      { name: 'commentTypeDisp', label: '種別' },
      { name: 'kpInfo', label: '場所' },
      { name: 'angleDisp', label: '方角' },
      { name: 'shareScopeLabel', label: '公開範囲' },
      { name: 'user_display_name', label: '作成者' },
      { name: 'contentDisp', label: '内容', type: 'html' },
      { name: 'hasFile', label: '添付' },
    ];
    const editModalTitle = '編集';
    const destroyModalTitle = '削除';
    const destroyModalMsg = 'この付箋を削除してもよろしいですか？';
    const errorModalTitle = 'エラー';
    const errorModalMsg = '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。';

    return {
      ...toRefs(state),
      msts,
      ACCEPTED_FILE_TYPES,
      // methods:
      doSearch,
      edit,
      tryDestroy,
      doDestroy,
      cancelEdit,
      save,
      handleFileUpload,
      removeFile,
      fileTypeIconFromFilePath,
      isImageMimeType,
      // computed:
      displayData,
      canSave,
      nearKps,
      editItemHasValidAngle,
      sortedPreviewFiles,
      // others:
      fields,
      editModalTitle,
      destroyModalTitle,
      destroyModalMsg,
      errorModalTitle,
      errorModalMsg,
    };
  },
  components: {
    PreviewFilesArea,
  },
});
