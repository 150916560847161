
import shareScopeConsts from '@/consts/share_scope';
import {
  getNearKps,
  getLocationDispOfKp,
  validateAngle,
  validateComment,
  fetchFileAsObjectInfo,
  MAX_SAVABLE_FILES_COUNT,
  ATTACHMENT_FILE_MAX_MB_DEFAULT,
  ACCEPTED_FILE_TYPES,
} from '@/lib/commentHelper';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api';
import { MapElemInfo, CandidateFile } from '@/models';
import { GIKp } from '@/models/geoItem';
import { Comment } from '@/models/apis/comment/commentResponse';
import { User } from '@/models/apis/user/userResponse';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { AxiosError } from 'axios';
import useMaster from '@/composables/useMaster';
import { splitByLineBreak } from '@/lib/utils';
import { dtFormat } from '@/lib/dateHelper';
import { downloadObjectUrl } from '@/lib/downloadHelper';
import {
  FileObj,
  convertFilesToFileObjs,
  fileTypeIconFromFilePath,
  isImageMimeType,
} from '@/lib/fileHelper';
import { extension as convertMimeTypeToExtension } from 'mime-types';
import PreviewFilesArea from '@/components/lib/PreviewFilesArea/index.vue';

interface EditComment extends Comment {
  updateTs?: boolean;
  saveFileObjs: FileObj[];
  saveFiles: File[];
  removeFileIds: number[];
}

interface MapElemInfoCommentState {
  mode: 'show' | 'edit';
  user: User;
  errorMsgs: string[];
  comment: Comment;
  // 動画画面でプレイヤーから切り出して渡される画像またはファイル選択の画像を保持する
  candidateFiles: CandidateFile[];
  update_ts: boolean;
  selectedKpObj: GIKp;
  editItem: EditComment;
}

interface MapElemInfoCommentElement extends MapElemInfo<Comment> {
  candidateImages: Blob[];
}

const DEFAULT_SHARE_SCOPE = shareScopeConsts.SHARE_SCOPE_GROUP3;

export default defineComponent({
  name: 'map-elem-info-comment',
  props: {
    isCreateSingleNewElement: {
      type: Boolean,
      default: false,
    },
    elem: {
      type: Object as PropType<MapElemInfoCommentElement>,
      required: true,
    },
    forceShowCancelButton: {
      type: Boolean,
      default: false,
    },
    updateFailed: {
      type: Number,
      default: 0,
    },
    createFailed: {
      type: Number,
      default: 0,
    },
    showMoveButtons: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive<MapElemInfoCommentState>({
      mode: 'show',
      user: {} as User,
      errorMsgs: [],
      comment: {} as Comment,
      candidateFiles: [],
      update_ts: false,
      selectedKpObj: {} as GIKp,
      editItem: {} as EditComment,
    });

    const startEdit = () => {
      state.mode = 'edit';
      state.update_ts = false;
      state.selectedKpObj = commentKpObject.value ?? nearKps.value.length > 0 ? nearKps.value[0] : {} as GIKp;
      const editItem: EditComment = {
        ...state.comment,
        comment_type: state.comment.comment_type || 'share',
        share_scope: state.comment.share_scope || DEFAULT_SHARE_SCOPE,
        content: splitByLineBreak(state.comment.content).join('\n'),
        angle: null,
        saveFileObjs: [],
        saveFiles: [],
        removeFileIds: [],
      };
      if (state.selectedKpObj && (editItem.angle === null || editItem.angle === undefined)) {
        editItem.angle = state.selectedKpObj.angle;
      }
      // 動画プレイヤーで付箋追加の場合は候補画像を表示する
      if (props.elem.candidateImages) {
        props.elem.candidateImages.forEach((image, idx) => {
          if (!image) return;
          state.candidateFiles.push({
            id: idx,
            content: convertBlobToImageSrc(image),
            type: image.type,
            name: '',
            selected: false,
          });
        });
        // 1枚だったら選択しておく
        if (state.candidateFiles.length === 1) {
          state.candidateFiles[0].selected = true;
        }
      }
      state.editItem = editItem;
      emit('component-resized');
    };
    const initDisplay = async() => {
      releaseAllFiles();
      state.errorMsgs = [];
      state.comment = props.elem.data || {};
      if (props.isCreateSingleNewElement) {
        startEdit();
      } else {
        state.mode = 'show';
      }
      if (!state.comment.comment_files || state.comment.comment_files.length === 0) {
        return;
      }
      await prepareCandidateFiles();
    };
    const prepareCandidateFiles = async(): Promise<void> => {
      state.comment.comment_files.forEach(async(file) => {
        const savedFileInfo = await fetchFileAsObjectInfo(file.file_path);
        if (!savedFileInfo || !savedFileInfo.url || !savedFileInfo.type) return;
        state.candidateFiles.push({
          id: file.id,
          content: savedFileInfo.url,
          type: savedFileInfo.type,
          name: file.file_name ?? '',
          selected: false,
        });
      });
      emit('component-resized');
    };
    const { state: msts } = useMaster();
    watch(() => msts.user, () => {
      state.user = msts.user;
    });
    onMounted(initDisplay);
    const releaseAllFiles = () => {
      if (state.editItem.saveFileObjs) {
        state.editItem.saveFileObjs.forEach(elm => URL.revokeObjectURL(elm.content));
      }
      state.editItem.saveFileObjs = [];
      state.editItem.removeFileIds = [];
      state.candidateFiles.forEach(elm => URL.revokeObjectURL(elm.content));
      state.candidateFiles = [];
    };
    onBeforeUnmount(() => {
      releaseAllFiles();
    });

    const convertBlobToImageSrc = (blob: Blob) => {
      return URL.createObjectURL(blob);
    };

    watch(() => props.elem, initDisplay);
    const createFailedMsg = '付箋追加に失敗しました。';
    const updateFailedMsg = '付箋更新に失敗しました。';
    watch(() => props.updateFailed, () => {
      if (!state.errorMsgs.includes(updateFailedMsg)) {
        state.errorMsgs.push(updateFailedMsg);
      }
    });
    watch(() => props.createFailed, () => {
      if (!state.errorMsgs.includes(createFailedMsg)) {
        state.errorMsgs.push(createFailedMsg);
      }
    });

    // computeds
    const nearKpThresholdMeters = 100;
    const nearKps = computed(() => {
      return getNearKps(
        {lat: props.elem.data.lat, lon: props.elem.data.lon},
        msts.kpMap,
        msts.roadNameDispMap,
        nearKpThresholdMeters,
      );
    });
    const commentKpObject = computed(() => {
      if (!props.elem.data?.kp_uid) { return null; }
      return nearKps.value.find(e => e.kp_uid === props.elem.data.kp_uid);
    });
    const iconPath = computed(() => {
      const commentType = msts.commentTypeMap[state.comment.comment_type];
      return commentType?.icon_path || '/static/img/comment_icon_04.png';
    });
    const shouldShowShareScope = computed(() => {
      return state.user.id === state.comment.user_id;
    });
    const canCopyLink = computed(() => {
      return !!window.navigator.clipboard;
    });
    const copyLinkTooltip = computed(() => {
      return canCopyLink.value ? 'リンクをコピー' : 'http環境ではこの機能は使えません';
    });
    const hasValidAngle = computed(() => {
      return validateAngle(state.comment.angle);
    });
    const canEdit = computed(() => {
      return state.user.id === state.comment.user_id;
    });
    const canDelete = computed(() => {
      return state.user.id === state.comment.user_id;
    });
    const editIconPath = computed(() => {
      const commentType = msts.commentTypeMap[state.editItem.comment_type];
      return commentType?.icon_path || '/static/img/comment_icon_04.png';
    });
    const isNew = computed(() => {
      return !state.comment.id;
    });
    const canUpdateTs = computed(() => {
      return !isNew.value && !state.comment.parent_id;
    });
    const editItemHasValidAngle = computed(() => {
      return validateAngle(state.editItem.angle);
    });
    const isFixedCandidateImages = computed(() => {
      // 動画プレイヤーから呼ばれたときは最初から候補画像が渡されている
      return props.elem?.candidateImages;
    });
    const canSave = computed(() => {
      const hasNoError = state.errorMsgs.length === 0;
      return validateComment(state.editItem) && hasNoError;
    });
    const showCancelButton = computed(() => {
      return props.forceShowCancelButton || !props.isCreateSingleNewElement;
    });
    const kpDisp = computed(() => {
      if (!commentKpObject.value) { return ''; }
      return getLocationDispOfKp(commentKpObject.value, msts.roadNameDispMap);
    });
    const sortedPreviewFiles = computed(() => {
      return state.candidateFiles.sort((a, b) => a.id - b.id);
    });

    // methods
    const copyLink = () => {
      const link = `${location.origin}/?comment_id=${props.elem.data.id}`;
      window.navigator.clipboard.writeText(link);
    };
    const showPrevious = () => {
      emit('show-previous', props.elem);
    };
    const showNext = () => {
      emit('show-next', props.elem);
    };
    const confirmDelete = () => {
      emit('confirm-delete', {
        dataName: 'comment',
        data: state.comment,
        confirmMessage: '選択中の付箋を削除してもよろしいですか？',
      });
    };
    const doAreaMovieSearch = () => {
      emit('area-movie-search', {
        lat: props.elem.data.lat,
        lon: props.elem.data.lon,
        radius: 500,
      });
    };
    const onSelectedKpObjChanged = () => {
      state.editItem = {
        ...state.editItem,
        angle: state.selectedKpObj && state.selectedKpObj.angle !== null ? state.selectedKpObj.angle : null,
      };
    };
    const handleFileUpload = async(fileList: FileList) => {
      state.errorMsgs = [];
      const uploadFilesCount = fileList.length + state.candidateFiles.length;
      try {
        if (uploadFilesCount > MAX_SAVABLE_FILES_COUNT) {
          throw Error('添付ファイルの最大数は' + MAX_SAVABLE_FILES_COUNT + '個です。');
        }
        // 新規ファイルのidを連番で割り振る(実際のidはサーバー側で振られる)
        const maxId = state.candidateFiles.map(e => e.id).reduce((a, b) => Math.max(a, b), 0);
        const fileObjs = await convertFilesToFileObjs(fileList, maxId, ATTACHMENT_FILE_MAX_MB_DEFAULT);
        state.candidateFiles.push(...fileObjs);
        state.editItem.saveFileObjs.push(...fileObjs);
      } catch (error) {
        state.errorMsgs.push(...getErrorMessages(error as AxiosError));
      }
    };
    const removeFile = (fileId: number) => {
      state.candidateFiles = state.candidateFiles.filter(e => e.id !== fileId);
      const saveFile = state.editItem.saveFileObjs.find(e => e.id === fileId);
      if (saveFile) {
        state.editItem.saveFileObjs = state.editItem.saveFileObjs.filter(e => e.id !== fileId);
        return;
      }
      state.editItem.removeFileIds.push(fileId);
    };
    const save = async() => {
      // 更新の場合のみ通知フラグを送信する。
      if (!props.isCreateSingleNewElement) {
        state.editItem.updateTs = state.update_ts;
      }

      // 動画プレイヤーで「現在位置に付箋を追加」をした場合は選択した画像を保存する
      const selectedFile = state.candidateFiles.find(e => e.selected);
      if (selectedFile && props.elem.candidateImages) {
        const selectedImage = props.elem.candidateImages[selectedFile.id];
        const selectedImageFile = new File([selectedImage], 'selectedImage', { type: selectedImage.type });
        state.editItem.saveFiles.push(selectedImageFile);
      } else {
        state.editItem.saveFiles = state.editItem.saveFileObjs.map(e => e.file);
      }
      state.editItem.kp_uid = state.selectedKpObj ? state.selectedKpObj.kp_uid : null;
      emit('update', { dataName: 'comment', data: state.editItem });
    };
    const showImage = (image: CandidateFile) => {
      const savedImage = image.content;
      const modalTitle = state.comment.content || '';
      const commentTs = dtFormat(state.comment.ts, 'yyyymmddHHMMSS');
      const downloadFilenameTpl = `付箋添付画像_${state.comment.id}_${commentTs}_$TIMESTAMP.jpg`;
      emit('show-image', {
        imageSrc: savedImage,
        modalTitle,
        downloadFilenameTpl,
      });
    };
    const downloadFile = (file: CandidateFile) => {
      const savedFile = file.content;
      const commentTs = dtFormat(state.comment.ts, 'yyyymmddHHMMSS');
      const timestamp = dtFormat(new Date(), 'yyyymmddHHMMSS');
      const extension = convertMimeTypeToExtension(file.type);
      const downloadFilenameTpl = `付箋添付ファイル_${state.comment.id}_${commentTs}_${timestamp}.${extension}`;
      downloadObjectUrl(savedFile, file.name || downloadFilenameTpl);
    };
    const cancelEdit = async() => {
      releaseAllFiles();
      if (props.forceShowCancelButton) {
        emit('cancel-edit');
        return;
      }
      state.mode = 'show';
      // 操作したファイルを元に戻す
      await prepareCandidateFiles();
      emit('component-resized');
    };
    const selectImage = (image: CandidateFile) => {
      state.candidateFiles.forEach(candidate => {
        if (candidate !== image) {
          candidate.selected = false;
        }
      });
      image.selected = !image.selected;
    };
    const removeFileCandidate = (fileId: number) => {
      state.candidateFiles = state.candidateFiles.filter(e => e.id !== fileId);
    };

    return {
      ...toRefs(state),
      msts,
      ACCEPTED_FILE_TYPES,
      // computeds
      nearKps,
      iconPath,
      shouldShowShareScope,
      copyLinkTooltip,
      canCopyLink,
      hasValidAngle,
      canEdit,
      canDelete,
      editIconPath,
      canUpdateTs,
      editItemHasValidAngle,
      isFixedCandidateImages,
      canSave,
      showCancelButton,
      kpDisp,
      sortedPreviewFiles,
      // methods
      copyLink,
      showPrevious,
      showNext,
      startEdit,
      confirmDelete,
      doAreaMovieSearch,
      onSelectedKpObjChanged,
      handleFileUpload,
      removeFile,
      save,
      showImage,
      downloadFile,
      cancelEdit,
      selectImage,
      removeFileCandidate,
      dtFormat,
      fileTypeIconFromFilePath,
      isImageMimeType,
      // others
      nearKpThresholdMeters,
    };
  },
  components: {
    PreviewFilesArea,
  },
});
