import axios, { AxiosResponse } from 'axios';
import { AdminCommentIndexRequest, AdminCommentUpdateRequest } from '@/models/apis/comment/adminCommentRequest';
import { AdminCommentIndexResponse } from '@/models/apis/comment/adminCommentResponse';
import {
  CommentIndexParams,
  CommentCreateParams,
  CommentUpdateParams,
} from '@/models/apis/comment/commentRequest';
import { CommentIndexResponse, Comment } from '@/models/apis/comment/commentResponse';
import * as utils from './apiUtils';

export default {
  index(params: CommentIndexParams): Promise<AxiosResponse<CommentIndexResponse>> {
    return axios.get(utils.getApiUrl('/comments'), {
      params: params || {},
    });
  },
  get(id: number): Promise<AxiosResponse<Comment>> {
    return axios.get(utils.getApiUrl(`/comments/${id}`));
  },
  create(params: CommentCreateParams): Promise<AxiosResponse<Comment>> {
    const formData = this._transformFormData(params);
    return axios.post(utils.getApiUrl('/comments'), formData);
  },
  update(id: number, params: CommentUpdateParams): Promise<AxiosResponse<Comment>> {
    const formData = this._transformFormData(params);
    // putではFormDataのパラメータを受け取れないため
    // https://qiita.com/ky0he1_0t4/items/6cd8264f646bdf86cdb9
    return axios.post(utils.getApiUrl(`/comments/${id}`), formData, {
      headers: {
        'X-HTTP-Method-Override': 'PUT',
      },
    });
  },
  _transformFormData(params: CommentCreateParams | CommentUpdateParams | AdminCommentUpdateRequest): FormData {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      // true', 'falseのままで送信するとサーバー側ではいずれもtrueになるため、
      // boolvalを利用する前提で'1', '0'で送信する。
      if (typeof value === 'boolean') {
        formData.append(key, value ? '1' : '0');
      } else if (Array.isArray(value)) {
        for (const obj of value) {
          formData.append(`${key}[]`, obj ?? '');
        }
      } else {
        formData.append(key, value ?? '');
      }
    });
    return formData;
  },
  destroy(id: number): Promise<AxiosResponse<Comment>> {
    return axios.delete(utils.getApiUrl(`/comments/${id}`));
  },

  adminIndex(params: AdminCommentIndexRequest): Promise<AxiosResponse<AdminCommentIndexResponse>> {
    return axios.get(utils.getApiUrl('/admin/comments'), {
      params: params || {},
    });
  },
  adminUpdate(id: number, params: AdminCommentUpdateRequest): Promise<AxiosResponse<Comment>> {
    const formData = this._transformFormData(params);
    return axios.post(utils.getApiUrl(`/admin/comments/${id}`), formData, {
      headers: {
        'X-HTTP-Method-Override': 'PUT',
      },
    });
  },
  adminDestroy(id: number): Promise<AxiosResponse<Comment>> {
    return axios.delete(utils.getApiUrl(`/admin/comments/${id}`));
  },
};
