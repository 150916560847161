import { getDistanceInMeters } from '@/lib/geoCalcHelper';
import fileApi from '@/apis/file';
import { KpMap, MapElemInfo, LocationRaw } from '@/models';
import { RoadNameDisp } from '@/models/apis/master/masterResponse';
import { GeoItemMetaComment, GIComment, GIKp } from '@/models/geoItem';
import GeoItemSearchComment from '@/components/Top/geoItemSearchComponents/GeoItemSearchComment.vue';

interface SelectRelativeCommentAfterDeletedParams {
  deletedObj: MapElemInfo<GIComment>;
  relativeComments: GIComment[];
  metaItem: GeoItemMetaComment;
  giSearchComment: InstanceType<typeof GeoItemSearchComment>;
}
/**
 * 付箋削除後に次の表示対象の付箋を返す。
 *
 * @param params.deleteObj 削除された付箋,
 * @param params.relativeComments 削除された付箋の親と子付箋,
 * @param params.metaItem 付箋のMetaItem
 * @param params.giSearchComment 付箋再検索条件
 * @return 次に表示する付箋またはnull
 */
export async function selectRelativeCommentAfterDeleted(
  params: SelectRelativeCommentAfterDeletedParams,
): Promise<MapElemInfo<GIComment> | null> {
  const deletedObj = params.deletedObj;
  const childComments = params.relativeComments.filter(e =>
    e.parent_id === deletedObj.data.id,
  );
  if (childComments.length > 0) {
    // 子付箋がある親付箋が削除された場合、サーバ側で子付箋が更新されているはずなので、対象データを取り直して設定する.
    const metaItem = params.metaItem;
    const updatedCommentsObj = await metaItem.manager.getResources(params.giSearchComment).then(data => {
      // 配列をidをキーとしたObjectに変換する.
      return data.reduce<Record<number, GIComment>>((obj, item) => {
        obj[item.id] = item; return obj;
      }, {});
    });
    const updatedChildComments: GIComment[] = [];
    childComments.forEach(origComment => {
      const updatedComment = updatedCommentsObj[origComment.id];
      if (updatedComment) {
        metaItem.layerManager.updateLayerItem(updatedComment);
        updatedChildComments.push(updatedComment);
      }
    });
    // 元子付箋の中に、親付箋に昇格したものが一個あるはずなのでそれを返す.
    const fistChildComment = updatedChildComments.find(e => e.isParentComment);
    if (!fistChildComment) {
      return null;
    }
    return {
      dataName: deletedObj.dataName,
      data: fistChildComment,
    };
  } else if (deletedObj.data.isParentComment) {
    // 親付箋で子付箋なしの場合はnull
    return null;
  }

  // 削除されたのが子付箋の場合、親または同じ親の付箋から次の表示付箋を取得する
  const relativeComment = deletedObj.data.isChildComment &&
    params.relativeComments.find(e =>
      e.id === deletedObj.data.parent_id || e.parent_id === deletedObj.data.parent_id,
    );
  if (!relativeComment) {
    return null;
  }

  return {
    dataName: deletedObj.dataName,
    data: relativeComment,
  };
}

/* 「場所」用文字列を構築して返す. */
export function getLocationDispOfKp(kpObj: GIKp, roadNameDispMap: Record<string, RoadNameDisp>): string {
  // 短い路線名
  const roadNameDispShort = roadNameDispMap[kpObj.road_name_disp].road_name_disp_short;
  // 全角のカッコが割と場所取るので半角に置換
  const placeName = kpObj.place_name === 'main_line'
    ? ''
    : kpObj.place_name
      .replace(/（/g, '(')
      .replace(/）/g, ')');
  // 小数第二位まで考慮、ブレーキがあったら表示追加
  let kpDisp = parseFloat(kpObj.kp.toString()).toFixed(2).replace(/0$/, '');
  if (kpObj.kp2 && kpObj.kp2 > 0) {
    kpDisp += `+${kpObj.kp2}`;
  }
  return [
    roadNameDispShort || kpObj.road_name_disp,
    kpObj.direction,
    placeName,
    `${kpDisp}km`,
  ].filter(e => !!e).join(' ');
}

export function getNearKps(
  {lat, lon}: LocationRaw,
  kpMap: KpMap,
  roadNameDispMap: Record<string, RoadNameDisp>,
  nearKpThresholdMeters: number,
): GIKp[] {
  const kps = [...kpMap.values()].map(e => [...e.values()]).flat(2);
  const nearKpEntsOrderedByDistance = kps.map(kp => {
    const distance = getDistanceInMeters(
      {lat: parseFloat(lat), lon: parseFloat(lon)},
      {lat: kp.lat, lon: kp.lon},
    );
    return { kp, distance };
  }).filter(e => {
    // 近くのkpを抽出する.
    return e.distance <= nearKpThresholdMeters;
  }).sort((a, b) => {
    const aDistance = a.distance;
    const bDistance = b.distance;
    return aDistance < bDistance ? -1 : aDistance > bDistance ? 1 : 0;
  });
  if (nearKpEntsOrderedByDistance.length === 0) { return []; }

  // 近い方から「場所 (road_name_disp,direction,place_name)」を取得しその順にする.
  // 同一の「場所」の中では近い順.
  const placeKeys: string[] = [];
  const kpsByPlacesMap: Record<string, Array<GIKp>> = {};
  nearKpEntsOrderedByDistance.forEach(({ kp }) => {
    const placeKey = `${kp.road_name_disp},${kp.direction},${kp.place_name}`;
    if (!kpsByPlacesMap[placeKey]) {
      kpsByPlacesMap[placeKey] = [];
      placeKeys.push(placeKey);
    }
    kpsByPlacesMap[placeKey].push(kp);
  });

  const ret = placeKeys.map(k => kpsByPlacesMap[k]).flat();
  return ret.map(kp => {
    return {
      ...kp,
      locationDisp: getLocationDispOfKp(kp, roadNameDispMap),
    };
  });
}

interface ValidateCommentParams {
  content?: string | null;
  comment_type?: string | null;
  share_scope?: number | null;
  angle?: number | null;
}

export function validateComment(comment: ValidateCommentParams): boolean {
  const hasCommentType = !!comment.comment_type;
  const hasShareScope = !!comment.share_scope;
  // eslint-disable-next-line no-irregular-whitespace
  const hasContent = (comment.content || '').replace(/^(\s|　)+$/, '').length > 0;
  const hasValidOrEmptyAngle = validateAngle(comment.angle) || !comment.angle;
  return hasCommentType && hasShareScope && hasContent && hasValidOrEmptyAngle;
}

export function validateAngle(angle: number | null | undefined): boolean {
  if (!angle) { return false; }
  return /^-?\d+$/.test(angle.toString()) &&
    parseInt(angle.toString()) >= -180 &&
    parseInt(angle.toString()) <= 180;
}

export const ATTACHMENT_FILE_MAX_MB_DEFAULT = 50;
export const MAX_SAVABLE_FILES_COUNT = 3;
export const ACCEPTED_FILE_TYPES = 'image/*, application/pdf, .doc, .docx, .xls, .xlsx';

export async function fetchFileAsObjectInfo(filePath: string): Promise<{
  url: string | null;
  type: string | null;
} | null> {
  if (!filePath) {
    return null;
  }
  const { data: blob } = await fileApi.getFile({path: filePath});
  return {
    url: URL.createObjectURL(blob),
    type: blob.type,
  };
}
